import { Map } from 'immutable';

const persistance = (state = Map(), action) => {
  switch (action.type) {
    case 'persist/REHYDRATE_SUCCESS':
      return Map(state); // this is how we solve the rehydrate-rerender problem
    default:
      return state;
  }
};

export default persistance;
