import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskOrderItemLayout.styles';
import {
  KioskWelcomeHeader,
  KioskWelcomeFooter,
  KioskOrderItemMain
  //AdminSideSlides
} from '..';

export class KioskOrderItemLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: 'Enter your user name and password.',
    height: 300
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <KioskWelcomeHeader myLogoheight={this.state.height} />
        {/* <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        /> */}
        <KioskOrderItemMain />
        <KioskWelcomeFooter customFooterBottom="0%" />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(KioskOrderItemLayout);
