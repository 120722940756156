import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminLoginLayout.styles';
//import { AdminHeader, AdminFooter, AdminLoginMain, AdminSideSlides } from '..';
import AdminHeader from './../AdminLayout/AdminHeader';
import AdminFooter from './../AdminLayout/AdminFooter';
import AdminSideSlides from './../AdminLayout/AdminSideSlides';
import AdminLoginMain from './AdminLoginMain';

export class AdminLoginLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: 'Enter your user name and password.',
    height: 300
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <AdminHeader myLogoheight={this.state.height} />
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminLoginMain />
        <AdminFooter customFooterBottom="0%" />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AdminLoginLayout);
