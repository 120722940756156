import { call, put, /* takeEvery, */ takeLatest } from 'redux-saga/effects';

function* enrollNewPatient(action) {
  try {
    const patient = yield call(
      action.payload.DriversLicenseNo,
      action.payload.FirstName,
      action.payload.LastName,
      action.payload.BirthDate,
      action.payload.ContactNo,
      action.payload.Email
    );
    yield put({ type: 'KIOSK/ENROLL_NEW_PATIENT', payload: { patient } });
  } catch (e) {}
}

function* kioskConfirmPickup(action) {
  try {
    const patient = yield call(
      action.payload.DriversLicenseNo,
      action.payload.FirstName,
      action.payload.LastName,
      action.payload.BirthDate,
      action.payload.Relation
    );
    yield put({ type: 'KIOSK/CONFIRM_USER', payload: { patient } });
  } catch (e) {}
}

export const kioskSagas = [
  takeLatest('KIOSK/ENROLL_NEW_PATIENT', enrollNewPatient),
  takeLatest('KIOSK/CONFIRM_USER', kioskConfirmPickup)
];
