// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './AdminRemoveForm.styles';
import AdminRemoveFormControls from './AdminRemoveFormControls';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

export class AdminRemoveForm extends Component {
  handleBack = () => {
    this.props.history.push('/RearStock/AdminPanel');
  };

  render() {
    const { classes, box } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.form)}>
          <AdminRemoveFormControls handleBack={this.handleBack} box={box} />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(AdminRemoveForm))
);
