import { Map } from 'immutable';
import { LOGIN_SUCCESS, LOGIN_FAILURE } from '../components/Login/LoginActions';
const auth = (state = Map(), action) => {
  switch (action.type) {
    // case 'UPDATE_DATA':
    //   return Map({
    //     ...state,
    //     box: action.box
    //   });
    // case 'UPDATE_POLLING_STATS':
    //   return Map({
    //     ...state,
    //     pollingStats: action.stats
    //   });
    case LOGIN_SUCCESS:
      // Here we just get the state.auth object
      // If other information are needed pass it in the action.context

      return Map({
        isAuthenticated: true,
        user: action.payload.user
      });
    case LOGIN_FAILURE:
      // Here we just get the state.auth object
      // If other information are needed pass it in the action.context

      return Map({
        isAuthenticated: false,
        user: null,
        error: action.payload.error
      });
    case 'AUTH/GET_CURRENT_USER_SUCCESS':
      return Map({
        isAuthenticated: true,
        user: action.payload.user
      });
    case 'AUTH/GET_CURRENT_USER_FAILURE':
      return Map({
        isAuthenticated: false,
        user: null
      });
    case 'AUTH/NEW_PASSWORD_REQUIRED':
      return Map({
        isAuthenticated: false,
        user: action.payload.user,
        NEW_PASSWORD_REQUIRED: true
      });
    case 'AUTH/PASSWORD_RESET_REQUIRED':
      return Map({
        isAuthenticated: false,
        username: action.payload.username,
        FORGOT_PASSWORD_REQUEST: true,
        PASSWORD_RESET_REQUIRED: true
      });
    // return Map({
    //   isAuthenticated: false,
    //   username: action.payload.username,
    //   error: action.payload.error,
    //   PASSWORD_RESET_REQUIRED: true,
    // });
    case 'AUTH/FORGOT_PASSWORD_REQUEST':
      return Map({
        isAuthenticated: false,
        username: action.payload.username,
        FORGOT_PASSWORD_REQUEST: true
      });
    case 'AUTH/VERIFICATION_CODE_SUCCESS':
      return Map({
        isAuthenticated: false,
        username: action.payload.username,
        FORGOT_PASSWORD_REQUEST: true
      });
    case 'AUTH/CONTACT_SUPPORT_REQUEST':
      return Map({
        isAuthenticated: false,
        username: action.payload.username,
        CONTACT_SUPPORT_REQUEST: true
      });
    case 'AUTH/NEW_PASSWORD_FAILED':
      return Map({
        isAuthenticated: false,
        user: action.payload.user,
        error: action.payload.error,
        NEW_PASSWORD_REQUIRED: true
      });
    case 'AUTH/VERIFICATION_CODE_FAILURE':
      return Map({
        isAuthenticated: false,
        username: action.payload.username,
        error: action.payload.error,
        FORGOT_PASSWORD_REQUEST: true
      });
    case 'persist/REHYDRATE_SUCCESS':
      return Map(state);
    default:
      return state;
  }
};

export default auth;
