// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './KioskPickupLayout.styles';
import { KioskHeader, KioskFooter, KioskPickupMain, KioskIdelScreen } from '..';

export class KioskPickupLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classNames(classes.container)}>
        <KioskHeader />
        <KioskPickupMain />
        <KioskFooter customFooterBottom="0%" isLanguageBarAllowd={true} />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(KioskPickupLayout);
