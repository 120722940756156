// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './AdminKioskVerifyForm.styles';
import AdminKioskVerifyFormControls from './AdminKioskVerifyFormControls';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';

export class AdminKioskVerifyForm extends Component {
  handleBack = () => {
    this.props.history.push('/RearStock/AdminPanel');
  };

  render() {
    const { classes, box } = this.props;

    return (
      <div className={classNames(classes.container)}>
        <div className={classNames(classes.form)}>
          <AdminKioskVerifyFormControls
            box={box}
            handleBack={this.handleBack}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(withRouter(AdminKioskVerifyForm))
);
