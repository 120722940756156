import { combineReducers } from 'redux-immutable';
import auth from './auth';
import persistance from './persistance';
import locale from './locale';
import kiosk from './kiosk';

export default combineReducers({
  auth,
  persistance,
  locale,
  kiosk
});
