import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './AdminSettingLayout.styles';
// import {
//   AdminHeader,
//   AdminFooter,
//   AdminSideSlides,
//   AdminSettingMain,
//   KioskIdelScreen
// } from '..';

import AdminHeader from './../AdminLayout/AdminHeader';
import AdminFooter from './../AdminLayout/AdminFooter';
import AdminSideSlides from './../AdminLayout/AdminSideSlides';
import AdminSettingMain from './AdminSettingMain';
import KioskIdelScreen from './../../KioskLayout/KioskIdelScreen';

export class AdminSettingLayout extends Component {
  static propTypes = {
    test: PropTypes.string
  };
  state = {
    stepTitle: '',
    pageTitle: 'Settings',
    height: 300
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classNames(classes.container)}>
        <AdminHeader myLogoheight={this.state.height} />
        <AdminSideSlides
          pageTitle={this.state.pageTitle}
          customTop={this.state.customTop}
          customHeight={120}
        />
        <AdminSettingMain />
        <AdminFooter customFooterBottom="0%" />
        <KioskIdelScreen />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AdminSettingLayout);
