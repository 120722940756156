// snippet: rcep
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import styles from './AdminSideSlides.styles';

export class AdminSideSlides extends Component {
  static propTypes = {
    test: PropTypes.string
  };

  render() {
    const { classes, pageTitle, customHeight } = this.props;
    var customTops;

    return (
      <div
        style={{ height: customHeight, marginTop: customTops }}
        className={classNames(classes.myContent)}
      >
        <div className={classNames(classes.subtitle)}>{pageTitle}</div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  injectIntl(AdminSideSlides)
);
